import { AnyAction } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { getReports, getReportsV2 } from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import {
  DateRange,
  FiltersState,
  LastEvaluatedObject,
  ReportsPayload,
} from './reducer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { AppDispatch, RootState } from '../../../store'
import { snakeCase } from 'lodash'
import { errorLogger } from '../../../common/utils/errorLogger'
import FileDownload from 'js-file-download'
import { useCurrentCloudProvider } from '../../../common/utils/Hooks/useCurrentCloudProvider'
import reportsData from '../../../mocks/revenueInsightsMockData.json'
import { convertObjectKeysAndValuesToCommaSeprated } from '../../../common/utils/helperFunctions'
import { paymentStatusArray } from '../../../common/utils/constants'

export enum RevenueTrackingActionTypes {
  SET_REPORTS_DATA = 'SET_REPORTS_DATA',
  REVENUE_SET_PAGE_NUMBER = 'REVENUE_SET_PAGE_NUMBER',
  REVENUE_SET_LIMIT = 'REVENUE_SET_LIMIT',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  CLEAR_REVENUE_REPORTS = 'CLEAR_REVENUE_REPORTS',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  SET_DATE_RANGE_TYPE = 'SET_DATE_RANGE_TYPE',
  SET_FILTERS = 'SET_FILTERS',
  CLEAR_REVENUE_FILTERS = 'CLEAR_REVENUE_FILTERS',
}
export type GetRevenueInsightsReportV2 = {
  partnerId: string
  finalStartDate: string
  finalEndDate: string
  downloadReport?: boolean
  pageSize: number
  pageNumber?: number
  productId?: string
  dateRangeType?: string
  paymentStatus?: string
  offerType?: string
  disbursementDate?: string | number
  paymentDueDate?: string | number
  paymentDelayedSince?: string | number
  lastEvaluatedKeys?: string
  lastEvaluatedValues?: string
  sortBy?: string
  sortOrder?: string
  shouldSendGetDateParam?: boolean
}

export const getRevenueInsights =
  (
    downloadReport: boolean,
    pageSizeValue?: number,
    lastEvaluatedObject?: LastEvaluatedObject | null
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!downloadReport) {
      await dispatch(startLoading(LoadingTypes.GENERAL))
    }
    try {
      const { dateRange, filters, dateRangeType, insertDates } =
        getState().revenueInsightsV2
      //TODO:LT-5307 When Azure enable for revenue then we have to do dynamic
      const cloudMarketplace = 'AWS' //getState().productListingFilters.cloudMarketplace
      const productId =
        getState().productsListing[cloudMarketplace].selectedProduct || ''
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const finalStartDate = dateRange.startDate
      const finalEndDate = dateRange.endDate
      const { pageSize: pageSizeStateValue } = getState().revenueInsightsV2
      const pageSize = pageSizeValue ? pageSizeValue : pageSizeStateValue
      const { keys: lastEvaluatedKeys, values: lastEvaluatedValues } =
        convertObjectKeysAndValuesToCommaSeprated(lastEvaluatedObject)
      const shouldSendGetDateParam =
        insertDates && Object.keys(insertDates).length > 0 ? false : true
      const formattedFilters = {
        ...filters,
        paymentStatus:
          filters.paymentStatus.length > 0
            ? JSON.stringify([...filters.paymentStatus].sort()) ===
              JSON.stringify(paymentStatusArray.sort())
              ? 'All'
              : filters.paymentStatus.join(',')
            : undefined,
      }
      if (!downloadReport) {
        const { data } = await getReportsV2({
          partnerId,
          finalStartDate,
          finalEndDate,
          pageSize,
          productId,
          dateRangeType,
          lastEvaluatedKeys,
          lastEvaluatedValues,
          shouldSendGetDateParam,
          ...formattedFilters,
        })
        await dispatch(setReportsData(camelize(data), pageSize))
      } else {
        await downloadCSVForRevenueInsightsV2({
          partnerId,
          finalStartDate,
          finalEndDate,
          pageSize,
          productId,
          dateRangeType,
          lastEvaluatedKeys,
          lastEvaluatedValues,
          downloadReport,
          ...formattedFilters,
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setReportsData = (data: ReportsPayload, pageSize: number) => ({
  type: RevenueTrackingActionTypes.SET_REPORTS_DATA,
  payload: { ...data, pageSize },
})
export const downloadCSVForRevenueInsightsV2 = async ({
  partnerId,
  finalStartDate,
  finalEndDate,
  pageSize,
  productId,
  dateRangeType,
  paymentStatus,
  offerType,
  disbursementDate,
  paymentDueDate,
  paymentDelayedSince,
  lastEvaluatedKeys,
  lastEvaluatedValues,
  sortBy,
  downloadReport,
  sortOrder,
}: GetRevenueInsightsReportV2) => {
  //TO DO LT-2930 update default parameters
  return getReportsV2({
    partnerId,
    finalStartDate,
    finalEndDate,
    pageSize,
    productId,
    dateRangeType,
    paymentStatus,
    offerType,
    disbursementDate,
    paymentDueDate,
    paymentDelayedSince,
    lastEvaluatedKeys,
    lastEvaluatedValues,
    sortBy,
    downloadReport,
    sortOrder,
  }).then(response => {
    FileDownload(response.data, `${finalStartDate}_${finalEndDate}.csv`)
  })
}

export const updateRevenuePageSize =
  (pageSize: number) => async (dispatch: AppDispatch) => {
    await dispatch(setRevenuePageSize(pageSize))
    await dispatch(getRevenueInsights(false) as unknown as AnyAction)
  }

export const setRevenuePageSize = (pageSize: number) => ({
  type: RevenueTrackingActionTypes.REVENUE_SET_LIMIT,
  payload: pageSize,
})

export const setRevenuePageNumber = (pageNumber: number) => ({
  type: RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER,
  payload: pageNumber,
})

export const clearRevenueReports = () => ({
  type: RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS,
})

export const setPageOneForRevenue = () => async (dispatch: AppDispatch) => {
  await dispatch(setRevenuePageNumber(1))
}

export const updateDateRange =
  (dates: DateRange) => async (dispatch: AppDispatch) => {
    const { startDate, endDate } = dates
    await dispatch(setDateRange({ startDate, endDate }))
    await dispatch(clearRevenueReports())
    await dispatch(setRevenuePageNumber(1))
  }
export const setDateRange = (dates: DateRange) => ({
  type: RevenueTrackingActionTypes.SET_DATE_RANGE,
  payload: dates,
})

export const setDateRangeType = (dateRangeType: string) => ({
  type: RevenueTrackingActionTypes.SET_DATE_RANGE_TYPE,
  payload: dateRangeType,
})
export const setFilters = (filters: FiltersState) => ({
  type: RevenueTrackingActionTypes.SET_FILTERS,
  payload: filters,
})
export const clearRevenueFilters = () => ({
  type: RevenueTrackingActionTypes.CLEAR_REVENUE_FILTERS,
})
