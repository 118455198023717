import {
  azureMarketplaceServiceUrl,
  crmServiceUrl,
  engineServiceUrl,
  gcpMarketplaceServiceUrl,
  marketplaceServiceUrl,
  identityServiceUrl,
  MarketplaceSearchUrl,
} from './constants'
import { matchPath } from 'react-router-dom'
import { FormField } from '@labrav/react-components/lib/@types/types/forms'
import { isEmpty, snakeCase } from 'lodash'
import { DateTime } from 'luxon'
import { CrmAceMappingState } from '../modules/onboarding/CrmAceMapping/reducer'
import { CRM } from '../modules/onboarding/crmSelector/action'
import { notificationMethod, rolesToCompare } from './constants'
import { Role } from './roles'
import { validate as isValidEmail } from 'email-validator'
import { MatchString } from './MatchString'
import { ReferralType } from '../../oppsync/components/CoSell/CoSellPipelineWrapper/CoSellPipelineWrapper'
import { CosellReferralType } from '../../oppsync/modules/CoSell/actions'
import { ReferralReferredBy } from '../../oppsync/modules/CoSell/reducer'
import { RadioButtonGroupMapping } from '@labrav/react-components'
export let windowObjectReference: Window | null = null
export let previousUrl: string | null = null
export const timeSince = (date: string) => {
  const iSODateString = new Date(date).toISOString()
  return DateTime.fromISO(iSODateString).toRelative()
}

export const capitalizeFirstLetter = (value?: string) => {
  if (!value) return ''
  return value.charAt(0)?.toUpperCase() + value.slice(1)
}

export const removeSpecialCharacters = (str: string) =>
  str.replace(/[^a-zA-Z 0-9.]+/g, ' ').toLowerCase()
export const sortObjectAlpbhabetic = (obj: any, sortBy: string) => {
  obj.sort(function (a: any, b: any) {
    const labelA = a[sortBy].toUpperCase()
    const labelB = b[sortBy].toUpperCase()
    return labelA < labelB ? -1 : labelA > labelB ? 1 : 0
  })
  return obj
}

export const checkRolePermissions = (roles: Role[]) => {
  let roleString
  if (roles.some(r => rolesToCompare.indexOf(r) == 4))
    return (roleString = Role.PARTNER_STAFF)

  if (roles.some(r => rolesToCompare.indexOf(r) == 3))
    return (roleString = Role.PARTNER_ADMIN)
  return (roleString = Role.PARTNER_OWNER)
}

export const setTabIndex = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const intersperse = (sep: unknown, arr: unknown[]) =>
  arr.reduce((a: unknown[], v: unknown) => [...a, v, sep], []).slice(0, -1)

export const replaceStringWithAny =
  (tokenToReplace: string) =>
  <T>(replaceWith: T) =>
  (content: string) => {
    const reg = new RegExp(tokenToReplace, 'gi')

    const splitedContent = content.split(reg)

    return intersperse(replaceWith, splitedContent)
  }
export const checkForInternalUser = (userRoles: Role[]) => {
  if (
    userRoles.some(r =>
      [Role.ADMIN, Role.SUPER_ADMIN, Role.ACCOUNT_EXECUTIVE].includes(r)
    )
  ) {
    return true
  } else {
    return false
  }
}

export const replaceMiddleWithLetter = (
  text: string,
  n: number,
  replaceTo: string
) => {
  const rest = text.length - n
  return (
    text.slice(0, Math.ceil(rest / 2) + 1) +
    replaceTo.repeat(n) +
    text.slice(-Math.floor(rest / 2) + 1)
  )
}

export const mappingFieldsAsString = (mappingData: CrmAceMappingState) =>
  Object.values(mappingData.mappingKeys.fields)
    .concat(mappingData.mappingKeys.crmMandatoryFields)
    .concat({
      crmKey: mappingData.mappingKeys.key,
      parentTable: mappingData.mappingKeys.parentTable,
    })
    .map(({ crmKey = '', parentTable = '' }) => parentTable + crmKey)
    .join(',')

export const getMethodPrefix = (method?: notificationMethod): string =>
  method
    ? {
        email: 'emails',
        text: 'phone numbers',
      }[method]
    : ''

export const getNotifcationTypeForUI = (notificationType: string) =>
  notificationType.replace('Notification', '')

//Open Window Pop UP
export const openSignInWindow = (
  name: string,
  url: string,
  receiveMessage: (event: MessageEvent) => void
) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - 600) / 2 / systemZoom + dualScreenLeft
  const top = (height - 600) / 2 / systemZoom + dualScreenTop
  window.removeEventListener('message', receiveMessage)
  const strWindowFeatures = `toolbar=no,menubar=no,width=600,height=600,top=${top},left=${left},modal=yes`
  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures)
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference?.focus()
  } else {
    windowObjectReference.focus()
  }
  window.onclick = () => windowObjectReference?.focus()
  window.onfocus = () => windowObjectReference?.focus()
  window.onunload = () => windowObjectReference?.close()
  window.addEventListener('message', receiveMessage, false)
  previousUrl = url
}

export const tabIndexToParamType =
  (index: number) =>
  <T>(paramsArr: T[]) =>
    paramsArr[index]

export const paramTypeToTabIndex =
  <T>(paramType: T) =>
  (paramsArr: T[]) =>
    paramsArr.indexOf(paramType)

/**
 * This is the getFirstPath function to return only first path value
 * @path  contains the value of the url path can be /oppsync/dashboard or product-selection?product=OppSync
 * @returns when receiving /oppsync/dashboard or product-selection?product=OppSync  return the first value
 */
export const getFirstPath = (path: any) => {
  path.indexOf(1)
  path.toLowerCase()
  path = path.split('/')[1]
  return path
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAuthData = (
  crmType: string,
  crm: string | CRM,
  partnerId: string,
  values: any,
  extraValues: any,
  environment?: string
) => {
  const defaultAuthData = {
    crm_type: crmType,
    creds:
      typeof crm !== 'string'
        ? crm.credential_fields.map(c => ({
            auth_key_name: c.key,
            auth_key_value: values[c.key].trim(),
          }))
        : values,
    environment_type: crm === 'salesforce' ? environment : 'production',
    partner_id: partnerId,
    ...extraValues,
  }

  return defaultAuthData
}

export const changeDefaultValue = (
  field: FormField,
  referralObject: Record<string, string>
) => {
  const defaultValue =
    !isEmptyValue(referralObject) && !isEmptyValue(referralObject[field.id])
      ? convertStringToDifferentType(referralObject[field.id], field.dataType)
      : field.defaultValue
  return defaultValue
}

export const convertStringToDifferentType = (value: string, type: string) => {
  switch (type) {
    case 'multi_enum':
      return value.split(';')
    case 'date': {
      const modifiedDate = !isEmpty(value) ? transformDate(value) : null

      return modifiedDate ? modifiedDate : null
    }

    default:
      return value
  }
}
export const getKeyByValue = (object: Record<string, any>, value: string) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const isValidDate = (date: Date) => {
  return new Date(date).toString() !== 'Invalid Date'
}

export const transformDate = (date: string) => {
  const trimmedDate = date.length > 10 ? date.slice(0, 10) : date

  return isValidDate(new Date(trimmedDate)) ? trimmedDate : null
}

export const isEmptyValue = (value: unknown) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const mergeDefaultValues = <T extends Record<string, unknown>>(
  mainObject: T,
  defaultValues: T
) => {
  return Object.keys(defaultValues).map(k => {
    if (!mainObject[k]) {
      return defaultValues[k]
    } else {
      return mainObject[k]
    }
  })
}

export const trimVersion = (version: string | undefined): string => {
  //this function return only first 2 characters of the version For e.g v1.23 -> v1
  return version ? version?.slice(0, 2) : '' || ''
}

export const isStringOfLength = (digitLength: number, value: string) =>
  RegExp(`^\\d{${digitLength}}$`, 'gm').test(value)

export const getRandom = () => Math.random()

/**
 * This is the changeReadOnlyBaseOnFieldAndValue function to change the value of readOnly in one or several Referral Form fields depending on the value of another specific field
 * e.g., if the defaultvalue for Acceptance status is Action required then the keys sent as fieldsToChange must be enabled
 * @param {FormField[]} fields contains all Referral fields and values
 * @param {String} keyToValidate  is the key of the field to be validated
 * @param {String} valueToValidate  value to compare or validate
 * @param {String[]} fieldsToChange  the field or fields to which the edition must be enabled by placing readOnly as false
 * @returns {FormField[]} new array of fields with the modifications if applicable
 */
export const changeReadOnlyBaseOnFieldAndValue = (
  fields: FormField[],
  keyToValidate: string,
  valueToValidate: string,
  fieldsToChange: string[]
) => {
  const isActionRequired =
    fields.find(f => f.id === keyToValidate)?.defaultValue === valueToValidate
  if (isActionRequired) {
    fieldsToChange.map(fieldToChange => {
      fields.map(field => {
        field.id === fieldToChange && (field.isReadOnly = false)
      })
    })
  }

  return fields
}
export const getSecureKeyFromPath = (path: string) =>
  matchPath(path, window?.location?.pathname)?.params?.secureKey

export const getSecureKey = () => {
  return (
    getSecureKeyFromPath('/external-apps/hubspot/v1/:secureKey/*') ||
    getSecureKeyFromPath('/external-apps/:secureKey/partners/*')
  )
}

export const getServiceNameFromUrl = (url: string) => {
  if (url.includes(marketplaceServiceUrl)) {
    return 'Marketplace'
  } else if (url.includes(engineServiceUrl) || url.includes(crmServiceUrl)) {
    return 'Crm'
  } else if (url.includes(azureMarketplaceServiceUrl)) {
    return 'azure_marketplace'
  } else if (url.includes(gcpMarketplaceServiceUrl)) {
    return 'gcp_marketplace'
  } else if (url.includes(identityServiceUrl)) {
    return 'identity'
  } else if (url.includes(MarketplaceSearchUrl)) {
    return 'search'
  }
}
export const formatCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: options?.currency || 'USD',
  }).format(value)
}

export const validateString = (str: string, key: string) => {
  if (isEmpty(str)) {
    return `${key} is required`
  } else if (str.match('[^a-zA-Z0-9]')) {
    return `${key} cannot have special characters`
  } else {
    return ''
  }
}

export const mapIdOptionsToValueOptions = (
  actualOptions: { label: string; id: string }[]
) => {
  return actualOptions?.map(item => {
    return { label: item.label, value: item.id }
  })
}

export const extractValidEmails = (tags: string) => {
  return tags
    .split(',')
    .map(email => email.trim())
    .filter(email => email !== '' && isValidEmail(email))
}

export const getUpdatedTags = (
  emails: string[],
  newEmailsInput: string
): string[] => {
  if (!newEmailsInput) {
    return emails
  }

  if (newEmailsInput.includes(',')) {
    const validEmails = extractValidEmails(newEmailsInput)
    const existingEmails = emails.filter(email => validEmails.includes(email))
    if (existingEmails.length === validEmails.length) {
      return emails
    }
    return [...new Set([...emails, ...validEmails])]
  } else if (
    MatchString(emails, newEmailsInput) &&
    isValidEmail(newEmailsInput)
  ) {
    return [...emails, newEmailsInput]
  }

  return emails
}

export const getReferralType = (
  referralType: CosellReferralType,
  referredBy: ReferralReferredBy
) => {
  if (referralType === 'lead') {
    return 'incoming-leads' as ReferralType
  } else if (referralType === 'opportunity' && referredBy === 'AWS Referral') {
    return 'incoming-referrals' as ReferralType
  } else {
    return 'outgoing-referrals' as ReferralType
  }
}

export const areAllEmailsValid = (emails: string[]): boolean => {
  return emails?.every(isValidEmail)
}

export const getUnique12Number = () =>
  Math.floor(100000000000 + Math.random() * 900000000000)

export const formatPastRange = (daysAgo: number): string => {
  const now = DateTime.now()
  const startDate = now.minus({ days: daysAgo }).toFormat('MMM dd')
  const endDate = now.toFormat('MMM dd')
  return `${startDate} - ${endDate}`
}
export const formatFutureRange = (
  daysAhead: number,
  endDateReturn?: boolean
): string => {
  const now = DateTime.now()
  const startDate = now.toFormat('MMM dd')
  const endDate = now.plus({ days: daysAhead }).toFormat('MMM dd')
  if (endDateReturn) {
    return endDate
  } else {
    return `${startDate} - ${endDate}`
  }
}
export const formatPastDate = (daysAgo: number): string => {
  return DateTime.now().minus({ days: daysAgo }).toFormat('MMM dd')
}
export const getLabelFromMapping = (
  mappingArray: RadioButtonGroupMapping[],
  key: string | null
) => mappingArray.find(item => item.key === key)?.label ?? ''

export const getDescriptionFromMapping = (
  mappingArray: RadioButtonGroupMapping[],
  key: string | null
) => mappingArray.find(item => item.key === key)?.description ?? ''
export const convertToFullDateTime = (dateString: string): Date => {
  const dateTime = DateTime.fromISO(dateString)
  return dateTime.toJSDate()
}
export const formatDateToISO = (date: Date): string => {
  const dateTime = DateTime.fromJSDate(date)
  return dateTime.toISODate() ?? ''
}

export const convertObjectKeysAndValuesToCommaSeprated = <
  T extends Record<string, string>
>(
  obj: T | null | undefined
): { keys: string | undefined; values: string | undefined } => {
  if (obj === null || typeof obj !== 'object' || Array.isArray(obj)) {
    return { keys: undefined, values: undefined }
  }

  const keys = Object.keys(obj).map(snakeCase).join(',')
  const values = Object.values(obj).join(',')

  return { keys, values }
}
