import { Customer } from '../../../admin/modules/NewCustomers/reducer'
import { useSelector } from '../../../store'
import { PartnerType } from '../../modules/partner/action'
import { ProductType } from '../../modules/partner/reducer'
import { CRMTypes } from '../constants'
import { isAdminRole } from '../isAdminRole'
import { UserRoleForTable } from '../roles'

const emptyCustomerForm = {
  companyName: '',
  quotationLink: '',
  internalNotes: '',
  crmType: CRMTypes.labra,
  numberOfSeats: 100,
  isInternal: false,
  includeLabraBot: false,
  users: [
    {
      email: '',
      role: 'PARTNER_OWNER' as UserRoleForTable,
    },
  ],
  subscribedProducts: {},
}

export const useUserType = () => {
  return useSelector<PartnerType>(state => {
    const isAdmin = isAdminRole(state.userProfile.userProfile)
    return isAdmin ? PartnerType.Admin : PartnerType.User
  })
}

export const usePartnerId = () => {
  const userType = useUserType()
  return useSelector<string | undefined>(
    state => state.PartnerData[userType]?.partnerData?.partnerId
  )
}

export const useCurrentProduct = (defaultProduct: ProductType) => {
  const userType = useUserType()
  return useSelector<ProductType>(
    state => state.PartnerData[userType]?.currentProduct || defaultProduct
  )
}

export const customerFormData = () => {
  const userType = useUserType()
  return useSelector<Customer>(state => {
    const partnerData = state?.PartnerData[userType]?.partnerData

    const newCustomerData = {
      companyName: partnerData?.companyName || emptyCustomerForm.companyName,
      quotationLink:
        partnerData?.quotationLink || emptyCustomerForm.quotationLink,
      internalNotes:
        partnerData?.internalNotes || emptyCustomerForm.internalNotes,
      crmType: partnerData?.crmType || emptyCustomerForm.crmType,
      numberOfSeats:
        partnerData?.numberOfSeats || emptyCustomerForm.numberOfSeats,
      isInternal: partnerData?.isInternal || emptyCustomerForm.isInternal,
      includeLabraBot:
        partnerData?.includeLabraBot || emptyCustomerForm.includeLabraBot,
      users: partnerData?.users || emptyCustomerForm.users,
      subscribedProducts:
        partnerData?.subscribedProducts || emptyCustomerForm.subscribedProducts,
    }
    return newCustomerData
  })
}
