import { Reducer } from 'redux'
import { RevenueTrackingActionTypes } from './actions'
import { DateTime } from 'luxon'
import { OfferType } from '../contractsListing/reducer'

export enum DisbursementStatus {
  NOT_DISBURSED = 'INVOICED',
  DISBURSED = 'DISBURSED',
  DEFAULTED = 'Defaulted',
}
export enum OfferStatusType {
  PRIVATE = 'private',
  PUBLIC = 'public',
  ALL = 'All',
}

export enum OfferVisibilitySatus {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}
export enum DateRangesTypeStatus {
  ALL = 'All',
  THIRTY = '30',
  SIXTY = '60',
  NINETY = '90',
}

export enum revenueDateTypeOptionsMap {
  INVOICE = 'invoice_date',
  PAYMENT_DUE = 'payment_due_date',
  DISBURSED = 'disbursement_date',
}
export const disbursementStatusMap = {
  'Not disbursed': DisbursementStatus.NOT_DISBURSED,
  Disbursed: DisbursementStatus.DISBURSED,
  Defaulted: DisbursementStatus.DEFAULTED,
}
export interface GraphData {
  date: string
  totalAmountActivated?: number
  totalAmountBilled?: number
  totalAmountDisbursed?: number
}
export interface DateRange {
  startDate: string
  endDate: string
}
export interface FiltersState {
  paymentStatus: string[]
  offerType: string
  disbursementDate: string
  paymentDueDate: string
  paymentDelayedSince: string
}
export interface ReportsRow {
  updatedAt: string
  invoiceId: string
  disbursementBillingEventId: string
  invoiceDate: string
  date: string
  productTitle: string
  labraProductId: string
  cloudMarketplaceProductCode: string
  labraBuyerName: string
  buyerId: string
  labraBuyerLogoUrl: string
  subscriberAWSAccountId: string
  activatedAmount?: string
  billedAmount?: string
  revenue?: string
  deductions?: string
  listingFeeRefund?: string
  disbursedAmount?: string
  netRevenue?: string
  paymentDueDate?: string
  disbursementStatus?: DisbursementStatus
  disbursementDate?: string
  paymentTerms?: string
  offerType?: OfferType
  agreementId?: string
  subscriberName?: string
  offerVisibility?: string
  bankTraceId?: string
  awsRevShare?: string
  awsTaxShare?: string
  awsTaxShareRefund?: string
  sellerRevShare?: string
  sellerTaxShare?: string
  sellerTaxRefund?: string
  sellerCompanyName?: string
  LabraSellerLogoUrl?: string
  awsTaxShareListingFee?: string
  awsTaxShareRefundListingFee?: string
  resellerName?: string
  resellerAWSAccountId?: string
  agreementAcceptanceDate?: string
  agreementStartDate?: string
  agreementEndDate?: string
  transactionReferenceId?: string
  brokerId?: string
  buyerCompanyName?: string
  postalCode?: string
  buyerEmailDomain?: string
  buyerCity?: string
  buyerStateOrRegion?: string
  buyerPostalCode?: string
  buyerCountryCode?: string
  buyerAddressId?: string
  buyerEmailAddress?: string
  privateOfferName?: string
  listingFeePercentage?: string
  listingFee?: string
  currency?: string
  grossRefund?: string
  grossAmount?: string
  wholeSaleCost?: string
  wholeSaleCostRefund?: string
  sellerNetRevenue?: string
  resaleAuthorizationId?: string
  resaleAuthorizationName?: string
  resellerCompanyName?: string
  payerCompanyName?: string
  payerAwsAccountId?: string
  payerEmailDomain?: string
  payerCity?: string
  payerStateOrRegion?: string
  payerCountryCode?: string
  payerPostalCode?: string
  payerAccountId?: string
  payerAddressId?: string
  buyerAwsAccountId?: string
  awsOfferId?: string
  awsSorId?: string
  resaleAuthorizationDescription?: string
}
export interface LastEvaluatedObject {
  [key: string]: string
}
export interface InsertDates {
  [key: string]: string
}

export interface ReportsData {
  records: ReportsRow[]
  totalAmountActivated?: string
  amountChange?: string
  percentageChange?: string
  totalAmountBilled?: string
  totalAmountDisbursed?: string
  pageSize: number
  pageNumber: number
  noOfPage: number | null
  graphData: GraphData[]
  dateRange: DateRange
  dateRangeType?: string
  filters: FiltersState
  lastEvaluatedObject?: LastEvaluatedObject | null
  count?: number
  tcv?: number
  insertDates?: InsertDates
}
export interface ReportsPayload {
  records: ReportsRow[]
  insertDates?: InsertDates
  totalAmountActivated?: string
  totalAmountBilled?: string
  totalAmountDisbursed?: string
  count: number
  pageSize: number
}
type actionType =
  | {
      type: RevenueTrackingActionTypes.SET_REPORTS_DATA
      payload: ReportsPayload
    }
  | {
      type: RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: RevenueTrackingActionTypes.REVENUE_SET_LIMIT
      payload: number
    }
  | {
      type: RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS
    }
  | {
      type: RevenueTrackingActionTypes.SET_DATE_RANGE
      payload: DateRange
    }
  | { type: RevenueTrackingActionTypes.SET_DATE_RANGE_TYPE; payload: string }
  | { type: RevenueTrackingActionTypes.SET_FILTERS; payload: FiltersState }
  | { type: RevenueTrackingActionTypes.CLEAR_REVENUE_FILTERS }

export const initialFilterRevenueState: FiltersState = {
  paymentStatus: [],
  offerType: OfferStatusType.ALL,
  disbursementDate: DateRangesTypeStatus.ALL,
  paymentDueDate: DateRangesTypeStatus.ALL,
  paymentDelayedSince: DateRangesTypeStatus.ALL,
}
export const initialState: ReportsData = {
  records: [],
  totalAmountActivated: '',
  totalAmountBilled: '',
  totalAmountDisbursed: '',
  pageSize: 15,
  pageNumber: 1,
  noOfPage: null,
  graphData: [],
  dateRange: {
    startDate: DateTime.now().minus({ months: 6 }).toISODate() as string,
    endDate: DateTime.now().toISODate() as string,
  },
  dateRangeType: revenueDateTypeOptionsMap.INVOICE,
  filters: {
    ...initialFilterRevenueState,
  },
  lastEvaluatedObject: null,
  count: 0,
  tcv: 0,
  insertDates: {},
}
export const reducer: Reducer<ReportsData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RevenueTrackingActionTypes.SET_REPORTS_DATA: {
      return {
        ...state,
        ...action.payload,
        records: [...state.records, ...action.payload.records],
        noOfPage: Math.ceil(action.payload.count / action.payload.pageSize),
        insertDates: {
          ...state.insertDates,
          ...action.payload?.insertDates,
        },
      }
    }
    case RevenueTrackingActionTypes.REVENUE_SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case RevenueTrackingActionTypes.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
      }
    }
    case RevenueTrackingActionTypes.SET_DATE_RANGE_TYPE: {
      return {
        ...state,
        dateRangeType: action.payload,
      }
    }
    case RevenueTrackingActionTypes.SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        lastEvaluatedObject: null,
      }
    }

    case RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS: {
      return {
        ...state,
        records: [],
        graphData: [],
        totalAmountActivated: '',
        amountChange: '',
        percentageChange: '',
        totalAmountBilled: '',
        totalAmountDisbursed: '',
        lastEvaluatedObject: null,
        tcv: 0,
      }
    }
    case RevenueTrackingActionTypes.CLEAR_REVENUE_FILTERS: {
      return {
        ...state,
        filters: {
          ...initialFilterRevenueState,
        },
        lastEvaluatedObject: null,
      }
    }
    default: {
      return state
    }
  }
}
