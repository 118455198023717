import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { AxiosResponse } from 'axios'
import {
  RequestFailureMessage,
  privateOfferCreationSuccess,
  privateOfferSaveSuccess,
} from '../../../common/utils/messagesContants'
import { AppDispatch, RootState } from '../../../store'
import { camelize, snakeize } from 'casing'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import {
  CloudMarketplace,
  Product,
  ProductDimension,
} from '../productsListing/reducer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  fetchSubscriptionList,
  getSearchedData,
  getSingleSubscriptionDetail,
} from '../../api/markeplace'
import subscriptionDetailJson from '../../../mocks/subscriptionDetail.json'
import { isEmpty } from 'lodash'
import {
  AgreementBasedOffer,
  EntitlementSubs,
  SubscriptionDetailObject,
} from './reducer'
import {
  convertDateFromAPIResponse,
  getPrivateOfferDataFromAPIResponse,
  patchOfferBasedOnCloud,
  postOfferBasedOnCloud,
  returnOnlySpecificDimensionsField,
} from '../privateOffer/privateOfferCreation/actions'
import {
  Dimension,
  DimensionType,
  EulaType,
  Installments,
} from '../privateOffer/reducer'
import { defaultCrmType } from '../../../common/utils/constants'
import { newrelicErrLogger } from '../../../common/utils/ErrorHandler'
export enum SubscriptionsActionTypes {
  SET_SUBSCRIPTIONS_DATA = 'SET_SUBSCRIPTIONS_DATA',
  CLEAR_SUBSCRIPTIONS_DATA = 'CLEAR_SUBSCRIPTIONS_DATA',
  SUBSCRIPTIONS_SET_PAGE_NUMBER = 'SUBSCRIPTIONS_SET_PAGE_NUMBER',
  SET_CURRENT_SUBSCRIPTION_DETAILS = 'SET_CURRENT_SUBSCRIPTION_DETAILS',
  CLEAR_CURRENT_SUBSCRIPTION_DETAILS = 'CLEAR_CURRENT_SUBSCRIPTION_DETAILS',
  SET_INPROGRESS_AGREEMENT_BASED_OFFER = 'SET_INPROGRESS_AGREEMENT_BASED_OFFER',
  UPDATE_NUMBER_OF_TIMES_SAVED_ON_AGREEMENT_BASED_OFFER = 'UPDATE_NUMBER_OF_TIMES_SAVED_ON_AGREEMENT_BASED_OFFER',
  UPDTE_AGREEMENT_BASED_OFFER_HAS_BEEN_SENT = 'UPDTE_AGREEMENT_BASED_OFFER_HAS_BEEN_SENT',
  CLEAR_INPROGRESS_AGREEMENT_BASED_OFFER = 'CLEAR_INPROGRESS_AGREEMENT_BASED_OFFER',
}

const getSubscriptionsAPIBasedOnCloud = async (
  partnerId: string,
  cloud: CloudMarketplace,
  productId?: string,
  pageSize?: number,
  pageNumber?: number,
  crmOpportunityId?: string,
  input?: string,
  selectedInput?: string,
  sectionName?: string
) => {
  switch (cloud) {
    case 'AWS': {
      if (isEmpty(input)) {
        return fetchSubscriptionList({
          partnerId,
          pageSize,
          pageNumber,
          crmOpportunityId,
        })
      } else {
        const response = await getSearchedData({
          partnerId,
          productId,
          input,
          selectedInput,
          pageSize,
          pageNumber,
          sectionName,
        })
        const totalCount = response?.data.total_count
        const rows = response?.data.records.map((recordItem: any) => ({
          ...recordItem._source,
          subscription_id: recordItem._source.id,
        }))
        return {
          ...response,
          data: {
            count: totalCount,
            subscriptions: rows,
          },
        }
      }
    }
  }
}

export const getSubscriptions =
  ({
    partnerId,
    productId,
    cloudMarketplace,
    pageSize,
    pageNumber,
    crmOpportunityId,
    input,
    selectedInput,
    sectionName,
  }: {
    partnerId: string
    productId?: string
    cloudMarketplace: CloudMarketplace
    pageSize?: number
    pageNumber?: number
    crmOpportunityId?: string
    input?: string
    selectedInput?: string
    sectionName?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await getSubscriptionsAPIBasedOnCloud(
        partnerId,
        cloudMarketplace,
        productId,
        pageSize,
        pageNumber,
        crmOpportunityId,
        input,
        selectedInput,
        sectionName
      )
      dispatch(
        await setSubscriptionsData(camelize({ ...response?.data, pageNumber }))
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setPageNumber = (pageNumber: number) =>
  ({
    type: SubscriptionsActionTypes.SUBSCRIPTIONS_SET_PAGE_NUMBER,
    payload: pageNumber,
  } as unknown as AnyAction)

export const clearSubscriptions = () =>
  ({
    type: SubscriptionsActionTypes.CLEAR_SUBSCRIPTIONS_DATA,
  } as unknown as AnyAction)

export const clearAgreementBasedOffer = (subscriptionId: string) =>
  ({
    type: SubscriptionsActionTypes.CLEAR_INPROGRESS_AGREEMENT_BASED_OFFER,
    payload: subscriptionId,
  } as unknown as AnyAction)

export const setSubscriptionsData = (data: Record<string, unknown>) =>
  ({
    type: SubscriptionsActionTypes.SET_SUBSCRIPTIONS_DATA as SubscriptionsActionTypes.SET_SUBSCRIPTIONS_DATA,
    payload: {
      data: data.subscriptions || [],
      pageNumber: data.pageNumber,
      count: data.count ?? 0,
      sortBy: data.sortBy ?? '',
      sortOrder: data.sortOrder ?? 'desc',
    },
  } as unknown as AnyAction)

const getSubscriptionDetailAPIBasedOnCloud = async (
  partnerId: string,
  subscriptionId: string,
  cloud: CloudMarketplace
) => {
  switch (cloud) {
    case 'AWS': {
      return await getSingleSubscriptionDetail(partnerId, subscriptionId)
    }
  }
}

export const getCurrentSubscriptionDetail =
  (
    partnerId: string,
    subscriptionsId: string,
    cloudMarketplace: CloudMarketplace
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await getSubscriptionDetailAPIBasedOnCloud(
        partnerId,
        subscriptionsId,
        cloudMarketplace
      )
      dispatch(setCurrentSubscriptionDetail(camelize(response?.data)))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

const getAgreementBasedOfferInitialPayload = ({
  cloudMarketplace,
  subscriptionDetail,
  product,
}: {
  cloudMarketplace: CloudMarketplace
  subscriptionDetail: SubscriptionDetailObject
  product?: Product
}) => {
  switch (cloudMarketplace) {
    case 'AWS': {
      const { offerDetails, entitlements, subscription } = subscriptionDetail
      const {
        offerRecipients,
        companyName,
        offerExpirationDate,
        privateOfferName,
        emailCustomMessage,
      } = offerDetails
      const converedDimensions = convertEntitlementstoDimensions(
        entitlements || [],
        product?.productDimensions
      ) as unknown as Dimension[]
      const dimensionsData =
        converedDimensions.filter(({ type }) => type === 'standard').length > 0
          ? converedDimensions
          : converedDimensions.concat([
              {
                label: '',
                labelDescription: '',
                type: 'standard' as DimensionType,
                isAdditionalUsageAllowed: false,
                currency: 'USD',
              },
            ])
      return {
        ...offerDetails,
        flexiblePaymentTerms: true,
        companyName: companyName || '',
        offerExpirationDate: offerExpirationDate || '',
        privateOfferName: privateOfferName || '',
        emailCustomMessage: emailCustomMessage || '',
        dimensions: dimensionsData,
        installments: [{ paymentDate: '', amount: null, currency: 'USD' }],
        eulaType: EulaType.STANDARD,
        eulaFile: '',
        renewal: false,
        previousSubscriptionEndDate: subscription?.expirationDate || null,
        offerRecipients:
          (offerRecipients && offerRecipients.length) > 0
            ? offerRecipients
            : [{ email: '', title: '', firstName: '', lastName: '' }],
      }
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
    default: {
      return {}
    }
  }
}

export const convertEntitlementstoDimensions = (
  entitlements: EntitlementSubs[],
  productDimensions?: ProductDimension[]
) => {
  return entitlements.map(
    ({
      crmObjectId,
      currency,
      dimensionId,
      dimensionLabel,
      dimensionName,
      labelDescription,
      price,
      value,
      isAdditionalUsageAllowed,
    }) => {
      const dimenionIdtobeUsed = productDimensions?.find(
        productDimension => productDimension.name === dimensionName
      )?.id
      return {
        crmObjectId,
        currency: currency || 'USD',
        dimensionId: dimenionIdtobeUsed || '',
        dimensionLabel,
        id: dimenionIdtobeUsed,
        label: dimensionLabel,
        dimensionName,
        name: dimensionName,
        labelDescription,
        price,
        quantity: value,
        isAdditionalUsageAllowed: !!isAdditionalUsageAllowed,
        type: isAdditionalUsageAllowed
          ? 'additional_usage'
          : !isEmpty(dimensionId)
          ? 'standard'
          : 'custom',
      }
    }
  )
}

export const getInProgressAgreementBasedOffer =
  (
    partnerId: string,
    subscriptionId: string,
    cloudMarketplace: CloudMarketplace,
    agreementOfferId?: string,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearAgreementBasedOffer(subscriptionId))
    try {
      const subscriptionDetail =
        getState().subscriptions.currentSubscription[subscriptionId]
      const product = getState().productsListing[
        cloudMarketplace
      ].products.rows.find(
        productItem =>
          productItem.productId ===
          subscriptionDetail?.productDetails?.labraProductId
      )
      if (isEmpty(agreementOfferId)) {
        const initialAgreementOffer = getAgreementBasedOfferInitialPayload({
          cloudMarketplace,
          subscriptionDetail,
          product,
        })
        dispatch(
          setInProgressAgreementBasedOffer({
            subscriptionId,
            data: initialAgreementOffer,
          })
        )
      } else {
        const subscriptionProductId =
          subscriptionDetail?.productDetails?.labraProductId
        const product =
          getState().productsListing[cloudMarketplace].products.rows.find(
            product => product.productId === subscriptionProductId
          ) || ({} as Product)
        const agrrementOfferData = await getPrivateOfferDataFromAPIResponse(
          cloudMarketplace,
          {
            productId: subscriptionProductId,
            partnerId,
            privateOfferId: agreementOfferId!,
            crmOpportunityId,
            product,
          },
          dispatch
        )
        const transformEmptyData = await getOfferTransformedDetail({
          cloudMarketplace,
          agrrementOfferData,
          subscriptionDetail,
        })
        dispatch(
          setInProgressAgreementBasedOffer({
            subscriptionId,
            data: transformEmptyData,
          })
        )
      }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getOfferTransformedDetail = async ({
  cloudMarketplace,
  agrrementOfferData,
  subscriptionDetail,
}: {
  cloudMarketplace: CloudMarketplace
  agrrementOfferData: any
  subscriptionDetail: SubscriptionDetailObject
}) => {
  switch (cloudMarketplace) {
    case 'AWS': {
      const updatedInstallments = checkIsNonEmptyArray(
        agrrementOfferData?.installments
      )
        ? agrrementOfferData?.installments
        : [{ paymentDate: '', amount: null, currency: 'USD' }]

      const dimensionsData =
        agrrementOfferData?.dimensions.filter(
          ({ type }: { type: string }) => type === 'standard'
        ).length > 0
          ? agrrementOfferData?.dimensions
          : agrrementOfferData?.dimensions.concat([
              {
                label: '',
                labelDescription: '',
                type: 'standard' as DimensionType,
                isAdditionalUsageAllowed: false,
                currency: 'USD',
              },
            ])
      const updatedRecipients = checkIsNonEmptyArray(
        agrrementOfferData?.offerRecipients
      )
        ? agrrementOfferData?.offerRecipients
        : [{ email: '', firstName: '', lastName: '', title: '' }]
      return {
        ...agrrementOfferData,
        installments: updatedInstallments,
        offerExpirationDate: convertDateFromAPIResponse(
          agrrementOfferData?.offerExpirationDate
        ),
        dimensions: [...dimensionsData],
        offerRecipients: updatedRecipients,
        subscriptionEndDate: convertDateFromAPIResponse(
          agrrementOfferData?.subscriptionEndDate
        ),
        previousSubscriptionEndDate:
          subscriptionDetail?.subscription?.expirationDate || null,
        eulaFile: checkIsNonEmptyArray(agrrementOfferData?.eulaFile)
          ? agrrementOfferData?.eulaFile[0]
          : '',
      }
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
  }
}

export const checkIsNonEmptyArray = (
  arrayData?: Record<string, unknown>[] | null
) => arrayData && arrayData.length > 0

export const setCurrentSubscriptionDetail = (data: Record<string, unknown>) =>
  ({
    type: SubscriptionsActionTypes.SET_CURRENT_SUBSCRIPTION_DETAILS,
    payload: data,
  } as unknown as AnyAction)

export interface InProgressAgreementBasedOfferProps {
  subscriptionId: string
  data: Record<string, unknown>
  noOfTimesSaved?: number
}

export const setInProgressAgreementBasedOffer = ({
  data,
  subscriptionId,
  noOfTimesSaved = 0,
}: InProgressAgreementBasedOfferProps) =>
  ({
    type: SubscriptionsActionTypes.SET_INPROGRESS_AGREEMENT_BASED_OFFER,
    payload: { subscriptionId, data, noOfTimesSaved },
  } as unknown as AnyAction)

export const clearCurrentSubscriptionDetail = () =>
  ({
    type: SubscriptionsActionTypes.CLEAR_CURRENT_SUBSCRIPTION_DETAILS,
  } as unknown as AnyAction)

const returnSpecificFieldsForRenewOffer = (
  agreementBasedOffer: AgreementBasedOffer
) => {
  const {
    crmOpportunityId,
    privateOfferId,
    awsAgreementId,
    awsAccountId,
    subscriptionEndDate,
    privateOfferName,
    flexiblePaymentTerms,
    eulaType,
    eulaFile,
    offerExpirationDate,
    offerRecipients,
    offerStakeholders,
    companyName,
    installments,
    emailCustomMessage,
    awsProductId,
    dimensions,
    renewal,
    renewalChannel
  } = agreementBasedOffer
  return {
    crmOpportunityId,
    privateOfferId,
    awsAgreementId,
    awsAccountId,
    subscriptionEndDate,
    privateOfferName,
    flexiblePaymentTerms,
    eulaType,
    eulaFile,
    offerExpirationDate,
    offerRecipients,
    offerStakeholders,
    companyName,
    installments,
    emailCustomMessage,
    awsProductId,
    dimensions,
    renewal,
    renewalChannel
  }
}

export const getFilteredDimensions = (dimensions: Dimension[]) => {
  return dimensions.filter(dimension => {
    if (!isEmpty(dimension.isAdditionalUsageAllowed)) {
      if (!isEmpty(dimension.dimensionId)) {
        return dimension
      }
    } else {
      if (
        !(
          isEmpty(dimension.name) &&
          isEmpty(dimension.labelDescription) &&
          isEmpty(dimension.quantity) &&
          isEmpty(dimension.label)
        )
      ) {
        return dimension
      }
    }
  })
}

export const getSubmitPayloadDataBasedOnCloud = ({
  selectedCloud,
  agreementBasedOffer,
  productId,
  agreementId,
  crmOpportunityId,
}: {
  selectedCloud: CloudMarketplace
  agreementBasedOffer: AgreementBasedOffer
  productId: string
  agreementId: string
  crmOpportunityId?: string
}) => {
  switch (selectedCloud) {
    case 'AWS': {
      const remainingAgreementBasedOffer =
        returnSpecificFieldsForRenewOffer(agreementBasedOffer)
      const dimensions = remainingAgreementBasedOffer.dimensions!.map(
        dimension => {
          if (dimension.isAdditionalUsageAllowed) {
            const { id, prices, quantity, type, ...remainingDimension } =
              dimension
            return {
              dimensionId: id,
              awsProductId: productId,
              ...remainingDimension,
            }
          }
          if (dimension.type === 'standard') {
            const { id, prices, price, currency, type, ...remainingDimension } =
              dimension
            return {
              dimensionId: id,
              awsProductId: productId,
              currency: currency || 'USD',
              ...remainingDimension,
            }
          }
          const { prices, price, currency, type, ...updatedDimension } =
            dimension
          return {
            awsProductId: productId,
            currency: currency || 'USD',
            ...updatedDimension,
          }
        }
      )
      const {
        installments,
        eulaFile,
        offerStakeholders,
        subscriptionEndDate,
        ...updatedAgreementOffer
      } = remainingAgreementBasedOffer
      const agreementDimensions = returnOnlySpecificDimensionsField(
        getFilteredDimensions(dimensions as unknown as Dimension[])
      )
      const agreementOfferToBeSent = {
        ...updatedAgreementOffer,
        dimensions: agreementDimensions,
        autoRenew: true,
        awsProductId: productId,
        offerStakeholders: offerStakeholders || [],
        zeroDollarPricing: false,
        installmentInfo: [...((installments as Installments[]) || [])],
        offerExpirationDate: isEmpty(
          remainingAgreementBasedOffer.offerExpirationDate
        )
          ? null
          : remainingAgreementBasedOffer.offerExpirationDate,
        ...(!isEmpty(subscriptionEndDate)
          ? {
              subscriptionEndDate: subscriptionEndDate,
            }
          : {}),
        eulaFile: eulaFile ? [eulaFile] : [],
        type: 'RENEWAL',
        aboAgreementId: agreementId,
        crmOpportunityId: crmOpportunityId,
      }

      return { ...agreementOfferToBeSent } as Record<string, unknown>
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
  }
}

export const submitRenewAgreementBasedOffer =
  ({
    subscriptionId,
    partnerId,
    productId,
    saveAsDraft,
    crmOpportunityId,
    crmObjectLink,
    formikOffer,
  }: {
    subscriptionId: string
    partnerId: string
    productId: string
    formikOffer: AgreementBasedOffer
    saveAsDraft?: boolean
    crmOpportunityId?: string
    crmObjectLink?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.RENEW_AGREEMENT_BASED_OFFER))
    try {
      const selectedCloud = getState().productListingFilters.cloudMarketplace
      const subscriptionDetail =
        getState().subscriptions.currentSubscription[subscriptionId]
      const agreementBasedOffer = formikOffer
      const agreementOfferToBeSent = getSubmitPayloadDataBasedOnCloud({
        selectedCloud,
        agreementBasedOffer,
        productId,
        agreementId: subscriptionDetail?.subscription?.awsAgreementId as string,
        crmOpportunityId,
      })

      let crmMetadata = {} as Record<string, unknown>
      if (crmOpportunityId && crmObjectLink) {
        crmMetadata = {
          crmType: defaultCrmType,
          crmObjectLink,
        }
      }

      const { privateOfferId, awsAgreementId, ...agreementOfferToBeSentId } =
        agreementOfferToBeSent
      if (saveAsDraft) {
        agreementOfferToBeSent.draft = true
      }

      if (isEmpty(privateOfferId)) {
        const payload = {
          partnerId,
          data: snakeize({
            ...agreementOfferToBeSentId,
            ...(crmOpportunityId ? { crmMetadata } : {}),
          }),
          saveAsDraft,
        }
        const response = await postOfferBasedOnCloud(selectedCloud, payload)
        if (response && response.data) {
          await updateFormikOfferAfterAPICall(
            formikOffer,
            dispatch,
            subscriptionId,
            0,
            camelize(response.data).privateOfferId ||
              (camelize(response.data).id as string)
          )
        }
      } else {
        const patchPayload = {
          partnerId,
          data: {
            ...snakeize({
              ...agreementOfferToBeSentId,
              draft: saveAsDraft,
              ...(crmOpportunityId ? { crmMetadata } : {}),
            }),
          },
          privateOfferId: privateOfferId as string,
          saveAsDraft,
        }
        await patchOfferBasedOnCloud(selectedCloud, patchPayload)
        await updateFormikOfferAfterAPICall(
          formikOffer,
          dispatch,
          subscriptionId,
          2,
          privateOfferId as string
        )
      }
      if (!saveAsDraft) {
        await dispatch(updateHasBeenSent(true, subscriptionId))
      } else {
        await dispatch(updateNoOfTimesSaved(subscriptionId))
      }
      dispatch(
        updateAppAlert({
          message: saveAsDraft
            ? privateOfferSaveSuccess
            : privateOfferCreationSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      newrelicErrLogger(error as Error, {
        message: error,
      })
    } finally {
      await dispatch(stopLoading(LoadingTypes.RENEW_AGREEMENT_BASED_OFFER))
    }
  }
const updateFormikOfferAfterAPICall = async (
  formikOffer: AgreementBasedOffer,
  dispatch: AppDispatch,
  subscriptionId: string,
  noOfTimesSaved: number,
  privateOfferId?: string
) => {
  await dispatch(
    setInProgressAgreementBasedOffer({
      subscriptionId,
      data: { ...formikOffer, privateOfferId: privateOfferId },
      noOfTimesSaved,
    })
  )
}

export const updateNoOfTimesSaved = (subscriptionId: string) => ({
  type: SubscriptionsActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_AGREEMENT_BASED_OFFER,
  payload: subscriptionId,
})

export const updateHasBeenSent = (
  hasBeenSent: boolean,
  subscriptionId: string
) => ({
  type: SubscriptionsActionTypes.UPDTE_AGREEMENT_BASED_OFFER_HAS_BEEN_SENT,
  payload: { hasBeenSent, subscriptionId },
})
