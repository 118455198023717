import {
  ActionMenuPopover,
  ColumnMenuOption,
  LoadingLogo,
  ModalConfirmV2,
  ModalSizeVariants,
  PopoverBgColors,
} from '@labrav/react-components'
import { useStyles } from './LabraHelpAndFeedback.styles'
import { Button, Link } from '@material-ui/core'
import { useCallback, useState } from 'react'
import { isLoading as Loading } from '../../utils/loadingState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faCircleInfo,
  faMonitorWaveform,
  faTicket,
} from '@fortawesome/pro-regular-svg-icons'
import {
  labraHelpCenter,
  labraSupportTicket,
  labraSystemStatus,
} from '../../utils/constants'
import { PartnerType } from '../../modules/partner/action'
import { usePartnerId, useUserType } from '../../utils/Hooks/usePartnerData'
import { useDispatch, useSelector } from '../../../store'
import { fetchG2ReviewUrl } from '../../modules/userProfile/action'
import { UserProfileState } from '../../modules/userProfile/reducer'
import { LoadingTypes } from '../../modules/loading/reducer'
import useScreenSize from '../../utils/Hooks/useScreenSize'

export const LabraHelpAndFeedback = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [g2ReviewUrl, setG2ReviewUrl] = useState<string>('')
  const partnerId = usePartnerId() || ''
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const isLoading = useSelector<boolean>(state =>
    Loading(state.loading)(LoadingTypes.GENERAL)
  )
  const isAdmin = useUserType() === PartnerType.Admin

  const isInternal = useSelector<boolean>(state => {
    const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
    return state.PartnerData?.[partnerType]?.partnerData?.isInternal || false
  })

  const { screenHeight } = useScreenSize()

  const modalHeight = `${(screenHeight || 500) * 0.65}px`

  const classes = useStyles(modalHeight)()

  const handleG2Modal = async () => {
    await setOpenModal(true)
    if (partnerId && userProfile?.id) {
      const response = await dispatch(
        fetchG2ReviewUrl({ partnerId, userId: userProfile?.id })
      )
      if (response?.url) {
        setG2ReviewUrl(response?.url)
      }
    }
  }

  const handleSelectOption = (
    option: ColumnMenuOption,
    handleClose?: (() => void) | undefined
  ) => {
    if (option?.key === 'g2-review-from') {
      handleG2Modal()
    }
  }

  const handleClose = useCallback(() => {
    setOpenModal(false)
  }, [openModal])

  const options = [
    {
      label: (
        <Link
          target="_blank"
          href={labraHelpCenter}
          className={classes.menuItem}
          data-testid={'help-center'}
        >
          <FontAwesomeIcon icon={faCircleInfo} width={'16px'} height={'16px'} />
          <span className={classes.menuText}>Help centre</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </Link>
      ),
      disable: false,
      key: 'help-center',
    },
    {
      label: (
        <Link
          target="_blank"
          href={labraSupportTicket}
          className={classes.menuItem}
          data-testid={'support-ticket'}
        >
          <FontAwesomeIcon icon={faTicket} width={'18px'} height={'16px'} />
          <span className={classes.menuText}>Submit a support ticket</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </Link>
      ),
      disable: false,
      key: 'support-ticket',
      borderBottom: true,
    },
    {
      label: (
        <div className={classes.menuItem}>
          <FontAwesomeIcon icon={faTicket} width={'18px'} height={'16px'} />
          <span className={classes.menuText}>Review us on G2</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </div>
      ),
      disable: !!isInternal,
      key: 'g2-review-from',
      borderBottom: true,
      disabledTooltip: 'G2 review is for external users only.',
    },
    {
      label: (
        <Link
          target="_blank"
          href={labraSystemStatus}
          className={classes.menuItem}
          data-testid={'labra-system-status'}
        >
          <FontAwesomeIcon
            icon={faMonitorWaveform}
            width={'18px'}
            height={'16px'}
          />
          <span className={classes.menuText}>Labra system status</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </Link>
      ),
      key: 'labra-system-status',
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <>
      <Button
        className={classes.actionButton}
        onClick={handleClick}
        data-testid={'labra-help-and-feedback'}
      >
        Help | Feedback
      </Button>
      <ActionMenuPopover
        bgColor={PopoverBgColors?.YELLOW}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menuOptions={options}
        handleSelectOption={handleSelectOption}
        toolTipPlacement={'left-start'}
      />
      <ModalConfirmV2
        content={
          isLoading ? (
            <LoadingLogo className={classes.loading} />
          ) : (
            <iframe
              style={{ minHeight: modalHeight }}
              width={'100%'}
              src={g2ReviewUrl}
            ></iframe>
          )
        }
        hideAcceptButton
        open={openModal}
        titleAcceptButton={'Okay'}
        onAccept={handleClose}
        onDecline={handleClose}
        variant={ModalSizeVariants.xLarge}
        hideCancelButton={true}
      />
    </>
  )
}
